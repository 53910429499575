table.compatability-checks [class*=" fa-"] {
    font-size: 2em;
    display: none;
}

.cookies i.cookies, .no-cookies i.no-cookies,
.ie8compat i.ie8compat, .no-ie8compat i.no-ie8compat,
.cors i.cors, .no-cors i.no-cors,
.json i.json, .no-json i.no-json,
.localstorage i.localstorage, .no-localstorage i.no-localstorage,
.localstorage i.rwlocalstorage, .localstorage i.no-rwlocalstorage, .no-localstorage i.no-rwlocalstorage,
.sessionstorage i.sessionstorage, .no-sessionstorage i.no-sessionstorage,
.sessionstorage i.rwsessionstorage, .sessionstorage i.no-rwsessionstorage, .no-sessionstorage i.no-rwsessionstorage,
.es5 i.es5, .no-es5 i.no-es5,
.promises i.promises, .no-promises i.no-promises,
.promises i.angular, .promises i.no-angular, .no-promises i.no-angular,
.xhrresponsetype i.xhrresponsetype, .no-xhrresponsetype i.no-xhrresponsetype,
.xhrresponsetypeblob i.xhrresponsetypeblob, .no-xhrresponsetypeblob i.no-xhrresponsetypeblob,
.xhrresponsetypedocument i.xhrresponsetypedocument, .no-xhrresponsetypedocument i.no-xhrresponsetypedocument,
.xhrresponsetypejson i.xhrresponsetypejson, .no-xhrresponsetypejson i.no-xhrresponsetypejson,
.xhrresponsetypetext i.xhrresponsetypetext, .no-xhrresponsetypetext i.no-xhrresponsetypetext,
.websockets i.websockets, .no-websockets i.no-websockets,
.websocketsbinary i.websocketsbinary, .no-websocketsbinary i.no-websocketsbinary,
.input i.input, .no-input i.no-input,
.inputtypes i.inputtypes, .no-inputtypes i.no-inputtypes,
.formvalidation i.formvalidation, .no-formvalidation i.no-formvalidation,
.fileinput i.fileinput, .no-fileinput i.no-fileinput,
.adownload i.adownload, .no-adownload i.no-adownload,
.atobbtoa i.atobbtoa, .no-atobbtoa i.no-atobbtoa,
.unicode i.unicode, .no-unicode i.no-unicode,
.fontface i.fontface, .no-fontface i.no-fontface,
.history i.history, .no-history i.no-history,
.canvas i.canvas, .no-canvas i.no-canvas,
.queryselector i.queryselector, .no-queryselector i.no-queryselector {
    display: block;
}
