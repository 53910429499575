.eo-sidebar {
    float: left;
    width: 230px;
    margin-right: 20px;
}

.eo-content {
    overflow: hidden;
}

.eo-list-item-border-green {
    border-left: #3faba4 2px solid;
}

.eo-list-item-border-blue {
    border-left: #4c87b9 2px solid;
}

.eo-list-item-border-purple {
    border-left: #8877a9 2px solid;
}

.eo-list-item-border-red {
    border-left: #d05454 2px solid;
}

.eo-list-item-border-yellow {
    border-left: #d4ad38 2px solid;
}

.padding-top-10px {
    padding-top: 15px;
}

.eo-userpic {
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
    border: 1px #cedae1 solid;
}

.eo-text-color {
    color: #45535b;
}

/* PROJECT LIST */
.eo-projects-config {
    padding: 6px 9px 3px 9px !important;
}

.eo-projects-config > i {
    font-size: 14px !important;
}

.eo-projects-config > .fa-angle-down {
    position: relative;
    display: inline-block;
    top: -1px;
    font-size: 13px !important;
    color: #f2f2f2;
}

.eo-tasklist {
    padding: 0;
}

.eo-project-list ul li a {
    font-size: 14px !important;
    padding: 8px 10px;
}

.eo-project-list .nav li a .badge {
    float: right;
    background-color: #b4c4cd !important;
    margin-top: 1px !important;
}

.eo-project-list .nav li a .badge-active {
    background-color: #ffffff !important;
    color: #637b89 !important;
}

.eo-project-list .nav .active {
    background-color: #ffffff !important;
}

.eo-project-list .nav-pills > li.active > a {
    background-color: #87a9c7 !important;
}

/* END PROJECT LIST */
/* TASK HISTORY */
.eo-task-history {
    padding: 0;
    margin: 0;
}

.eo-task-history > li {
    padding: 5px 0;
}

.eo-task-history > li > .eo-task-history-desc {
    overflow: hidden;
}

.eo-task-history > li > .eo-task-history-date {
    font-size: 12px;
    float: right;
    width: 150px;
    margin-left: 10px;
    text-align: right;
    color: #999;
}

/* TASKS LIST */
.eo-list-item {
    background: #f6fbfc;
    padding: 10px;
    margin-bottom: 10px;
    margin-bottom: 15px;
    overflow: hidden;
}

.eo-list-item:last-child {
    margin-bottom: 0;
}

div .eo-list-item:hover {
    cursor: pointer;
    background-color: #edf7f9;
}

.eo-list-item img {
    margin: 0 10px 10px 0;
}

.eo-list-item-title {
    font-size: 15px;
    color: #2b4a5c;
    font-weight: 600;
    padding-top: 3px;
    padding-bottom: 13px;
}

.eo-list-item-text {
    font-size: 13px;
    color: #577688;
    padding-bottom: 5px;
}

.eo-list-item ul {
    margin: 5px 0 0 0px;
}

.eo-list-item li {
    color: #577688;
    font-size: 13px;
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 0 !important;
}

.eo-list-item li i {
    color: #b3bfcb;
    font-size: 15px;
}

.eo-list-controls {
    margin-top: 5px;
}

.eo-list-date {
    color: #637b89 !important;
    margin-right: 12px;
}

.eo-list-date i {
    color: #abbfca !important;
    margin-right: 5px;
}

.eo-list-badge {

}

/* END TASKS LIST */
/* TASK BODY */
.eo-taskbody-tasktitle {
    font-size: 18px;
    color: #778d96;
}

.eo-taskbody-taskdesc {
    font-size: 14px;
    color: #778d96;
}

.eo-username {
    font-size: 16px;
    color: #2b4a5c;
    font-weight: 600;
    padding: 15px 0 0 15px;
}

.eo-comment-head {
    padding-top: 3px;
}

.eo-comment {
    position: relative;
}

.eo-comment:hover > .eo-comment-btn {
    display: block;
}

.eo-comment-btn {
    display: none;
    position: absolute;
    top: 1px;
    right: 0px;
    font-size: 12px;
    color: #566e7c;
    border-color: #a2aeb5;
}

.eo-comment-btn:hover {
    color: #fff;
    background-color: #a1b6c2;
    border-color: #a1b6c2;
}

.eo-comment-username {
    font-size: 14px;
    color: #2b4a5c;
    font-weight: 600;
}

.eo-comment-date {
    font-size: 12px;
    color: #2b4a5c;
    font-weight: 400;
}

.eo-username-btn {
    margin: 14px 0 0 15px;
    color: #566e7c;
    border-color: #a2aeb5;
}

.eo-username-btn:hover {
    color: #fff;
    background-color: #a1b6c2;
    border-color: #a1b6c2;
}

.form .form-actions.eo-form-actions {
    padding-top: 10px;
    border: 0;
    margin: 0 0 20px 0;
}

/* END TASK BODY */
/* RESPONSIVE MODE */
@media (max-width: 991px) {
    /* 991px */
    .eo-sidebar {
        float: none;
        width: 100%;
        margin: 0;
    }

    .eo-sidebar > .portlet {
        margin-bottom: 20px;
    }

    .eo-sidebar > .portlet .portlet-title .tools {
        margin-left: 5px;
    }

    .eo-content {
        overflow: visible;
    }
}

.eo-list-divider {
    display: none;
}

@media (max-width: 767px) {
    /* 767px */
    .eo-list-divider {
        display: block;
        height: 20px;
        margin: 20px -10px;
        background: #F1F3FA;
    }

    /* TASK HISTORY */
    .eo-task-history > li {
        padding: 9px 0;
    }

    .eo-task-history > li > .eo-task-history-date {
        width: 100px;
        font-size: 11px;
    }
}
