.job_timeline {
    margin: 20px 0;
    padding: 0;
    list-style: none;
}

.job_timeline__step {
    float: left;
    width: 100px;
    padding-top: 30px;
    position: relative;
    border-top: 2px solid #428bca;
}

.job_timeline__step:first-of-type:before, .job_timeline__step:last-of-type:before {
    content: "";
    width: 50%;
    height: 100%;
    background: #fff;
    position: absolute;
    top: -2px;
    left: 0;
}

.job_timeline__step:last-of-type:before {
    left: 50%;
}

.job_timeline__step:hover .job_timeline__step-label {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    height: auto;
    padding: 5px 10px;
    font-size: 12px;
    border-width: 1px;
    bottom: 100%;
}

.job_timeline__step:hover .job_timeline__step-label:before, .job_timeline__step:hover .job_timeline__step-label:after {
    display: block;
}

.job_timeline__step-label {
    opacity: 0;
    height: 0;
    padding: 0;
    font-size: 0;
    border-width: 0;
    -webkit-transform: rotate(30deg);
    width: 110px;
    margin: 0 0 45px -55px;
    border-style: solid;
    border-color: #428bca;
    background: #fff;
    font-weight: normal;
    position: absolute;
    left: 50%;
    bottom: 0;
    transition: bottom 0.1s ease-in-out, opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
    max-width: none !important;
}

.job_timeline__step-label:before, .job_timeline__step-label:after {
    display: none;
    content: "";
    width: 0;
    height: 0;
    border: solid transparent;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 50%;
}

.job_timeline__step-label:before {
    border-top-color: #428bca;
    border-width: 8px;
    margin-left: -8px;
}

.job_timeline__step-label:after {
    border-top-color: #fff;
    border-width: 6px;
    margin-left: -6px;
}

.job_timeline__step-content {
    display: block;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.job_timeline__step-title {
    display: block;
    width: 100%;
    padding: 30px 0 0;
    font-size: 12px;
    text-align: center;
    position: absolute;
    top: 0;
}

.job_timeline__step-marker {
    width: 50px;
    height: 50px;
    margin-left: -25px;
    background: #fff;
    border: 4px solid #428bca;
    border-radius: 50%;
    box-shadow: 0 0 0 10px #fff;
    text-align: center;
    line-height: 50px -10;
    color: #428bca;
    font-size: 2em;
    font-style: normal;
    position: absolute;
    top: -26px;
    left: 50%;
}

.done .job_timeline__step-marker {
    background: #428bca;
    color: #fff;
}

.job_timeline__step-radio {
    display: none;
    /**
     * not needed for now,
     * because markers won't be clicked in horz version
     */ }

.job_timeline--summary {
    width: 400px;
    border-width: 2px;
}
.job_timeline--summary .job_timeline__step {
    float: left;
    width: 50px;
    padding-top: 30px;
    position: relative;
    border-top: 2px solid #428bca;
}
.job_timeline--summary .job_timeline__step-label {
    margin-bottom: 19px;
}
.job_timeline--summary .job_timeline__step-title {
    padding: 10px 0 0;
    font-size: 10px;
    font-weight: bold;
}
.job_timeline--summary .job_timeline__step-marker {
    width: 12px;
    height: 12px;
    margin-left: -6px;
    border-width: 2px;
    text-indent: -999em;
    box-shadow: 0 0 0 3px #fff;
    top: -7px;
}