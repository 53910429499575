/***
Profile Page
***/
.profile {
    position: relative;
}

.page-container-bg-solid .profile {
    background: #ffffff;
    padding: 20px;
}

.profile p {
    color: #636363;
    font-size: 13px;
}

.profile p a {
    color: #169ef4;
}

.profile label {
    margin-top: 10px;
}

.profile label:first-child {
    margin-top: 0;
}

/*profile info*/
.profile-classic .profile-image {
    position: relative;
}

.profile-classic .profile-edit {
    top: 0;
    right: 0;
    margin: 0;
    color: #fff;
    opacity: 0.6;
    padding: 0 9px;
    font-size: 11px;
    background: #000;
    position: absolute;
    filter: alpha(opacity=60);
    /*for ie*/
}

.profile-classic .profile-image img {
    margin-bottom: 15px;
}

.profile-classic li {
    padding: 8px 0;
    font-size: 13px;
    border-top: solid 1px #f5f5f5;
}

.profile-classic li:first-child {
    border-top: none;
}

.profile-classic li span {
    color: #666;
    font-size: 13px;
    margin-right: 7px;
}

/*profile tabs*/
.profile .tabbable-custom-profile .nav-tabs > li > a {
    padding: 6px 12px;
}

/*profile navigation*/
.profile ul.profile-nav {
    margin-bottom: 30px;
}

.profile ul.profile-nav img.pic-bordered {
    border: 1px solid #e1e7ee;
}

.profile ul.profile-nav li {
    position: relative;
}

.profile ul.profile-nav li a {
    color: #557386;
    display: block;
    font-size: 14px;
    padding: 8px 10px;
    margin-bottom: 1px;
    background: #f0f6fa;
    border-left: solid 2px #c4d5df;
}

.profile ul.profile-nav li a:hover {
    color: #169ef4;
    background: #ecf5fb;
    text-decoration: none;
    border-left: solid 2px #169ef4;
}

.profile ul.profile-nav li a.profile-edit {
    top: 0;
    right: 0;
    margin: 0;
    color: #fff;
    opacity: 0.6;
    border: none;
    padding: 3px 9px;
    font-size: 12px;
    background: #000;
    position: absolute;
    filter: alpha(opacity=60);
    /*for ie*/
}

.profile ul.profile-nav li a.profile-edit:hover {
    text-decoration: underline;
}

.profile ul.profile-nav a span {
    top: 0;
    right: 0;
    color: #fff;
    font-size: 16px;
    padding: 7px 13px;
    position: absolute;
    background: #169ef4;
}

.profile ul.profile-nav a:hover span {
    background: #0b94ea;
}

/*profile information*/
.profile-info h1 {
    color: #383839;
    font-size: 24px;
    font-weight: 400;
    margin: 0 0 10px 0;
}

.profile-info ul {
    margin-bottom: 15px;
}

.profile-info li {
    color: #6b6b6b;
    font-size: 13px;
    margin-right: 15px;
    margin-bottom: 5px;
    padding: 0 !important;
}

.profile-info li i {
    color: #b5c1c9;
    font-size: 15px;
}

.profile-info li:hover i {
    color: #169ef4;
}

/*profile sales summary*/
.sale-summary ul {
    margin-top: -10px;
}

.sale-summary li {
    padding: 10px 0;
    overflow: hidden;
    border-top: solid 1px #eee;
}

.sale-summary li:first-child {
    border-top: none;
}

.sale-summary li .sale-info {
    margin-top: 4px;
    float: left;
    color: #646464;
    font-size: 14px;
    text-transform: uppercase;
}

.sale-summary li .sale-num {
    float: right;
    color: #169ef4;
    font-size: 20px;
    font-weight: 300;
}

.sale-summary li span i {
    top: 1px;
    width: 13px;
    height: 14px;
    margin-left: 3px;
    position: relative;
    display: inline-block;
}

.sale-summary .caption h4 {
    color: #383839;
    font-size: 18px;
}

.sale-summary .caption {
    border-color: #c9c9c9;
}

/*latest customers table*/
.profile .table-advance thead tr th {
    background: #f0f6fa;
}

.profile .table-bordered th,
.profile .table-bordered td,
.profile .table-bordered {
    border-color: #e5eff6;
}

.profile .table-striped tbody > tr:nth-child(2n+1) > td,
.profile .table-striped tbody > tr:nth-child(2n+1) > th {
    background: #fcfcfc;
}

.profile .table-hover tbody tr:hover td,
.profile .table-hover tbody tr:hover th {
    background: #f5fafd;
}

/*add portfolio*/
.add-portfolio {
    overflow: hidden;
    margin-bottom: 30px;
    background: #f0f6fa;
    padding: 12px 14px;
}

.add-portfolio span {
    float: left;
    display: inline-block;
    font-weight: 300;
    font-size: 22px;
    margin-top: 0px;
}

.add-portfolio .btn {
    margin-left: 20px;
}

/*portfolio block*/
.portfolio-block {
    background: #f7f7f7;
    margin-bottom: 15px;
    overflow: hidden;
}

.portfolio-stat {
    overflow: hidden;
}

/*portfolio text*/
.portfolio-text {
    overflow: hidden;
}

.portfolio-text img {
    float: left;
    margin-right: 15px;
}

.portfolio-text .portfolio-text-info {
    overflow: hidden;
}

/*portfolio button*/
.portfolio-btn a {
    display: block;
    padding: 25px 0;
    background: #ddd !important;
}

.portfolio-btn a:hover {
    background: #1d943b !important;
}

.portfolio-btn span {
    color: #fff;
    font-size: 22px;
    font-weight: 200;
}

/*portfolio info*/
.portfolio-info {
    float: left;
    color: #616161;
    font-size: 12px;
    padding: 10px 25px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.portfolio-info span {
    color: #16a1f2;
    display: block;
    font-size: 28px;
    line-height: 28px;
    margin-top: 0px;
    font-weight: 200;
    text-transform: uppercase;
}

/*portfolio settings*/
.profile-settings {
    background: #fafafa;
    padding: 15px 8px 0;
    margin-bottom: 5px;
}

.profile-settings p {
    padding-left: 5px;
    margin-bottom: 3px;
}

.profile-settings .controls > .radio,
.profile-settings .controls > .checkbox {
    font-size: 12px;
    margin-top: 2px !important;
}
