#bg{
    position:absolute;
    z-index:0;
    background:white;
    display:block;
    min-height:90%;
    min-width:90%;
}

#bg-content{
    position:relative;
    z-index:1;
}

#bg-text
{
    color:lightgrey;
    font-size:150px;
    transform:rotate(300deg);
    -webkit-transform:rotate(300deg);
}
