@import url('https://fonts.googleapis.com/css?family=Dancing+Script');
@import url('https://fonts.googleapis.com/css?family=UnifrakturCook:700');

.certificate {
    font-family: "Dancing Script", sans-serif;
    font-size: 24px;
    direction: ltr;
}

.certificate-title {
    font-family: 'UnifrakturCook', cursive;
    font-size: 64px;
    direction: ltr;
}


/* Announcement */

#announcement {
    padding: .34em 1em;
    margin: 10px 0 0 0;
    position: relative;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, .3),
    inset 0px 0px 20px rgba(0, 0, 0, 0.1),
    0px 1px 1px rgba(0, 0, 0, 0.4);
    background-color: #1eb2df;
    background: -webkit-linear-gradient(top, #1eb2df, #17a7d2);
    display: inline-block;
}

#announcement:before, #announcement:after {
    content: "";
    width: .2em;
    bottom: -.5em;
    position: absolute;
    display: block;
    border: .9em solid #1eb2df;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.4);
    z-index: -2;
}

#announcement:before {
    left: -1.15em;
    border-right-width: .75em;
    border-left-color: transparent;
}

#announcement:after {
    right: -1.15em;
    border-left-width: .75em;
    border-right-color: transparent;
}

#content:before, #content:after {
    content: "";
    bottom: -.5em;
    position: absolute;
    display: block;
    border-style: solid;
    border-color: #0675b3 transparent transparent transparent;
    z-index: -1;
}

#content:before {
    left: 0;
    border-width: .5em 0 0 .5em;
}

#content:after {
    right: 0;
    border-width: .5em .5em 0 0;
}