/* Fix for Bootstrap 3 with Angular UI Bootstrap */
.modal {
    display: block;
}

.modal-dialog {
    margin: 200px auto;
}

.modal .modal-header {
    border-bottom: 1px solid #EFEFEF;
    margin-top: 0 !important;
}

/* Custom dialog/modal headers */
.dialog-header-error {
    background-color: #d2322d;
}

.dialog-header-success {
    background-color: #47a447;
}

.dialog-header-warning {
    background-color: #DB4D6D;
}

.dialog-header-wait {
    background-color: #428bca;
}

.dialog-header-notify {
    background-color: #eeeeee;
}

.dialog-header-confirm {
    background-color: #333333;
}

.dialog-header-error span, .dialog-header-error h4,
.dialog-header-success span, .dialog-header-success h4,
.dialog-header-wait span, .dialog-header-wait h4,
.dialog-header-confirm span, .dialog-header-confirm h4 {
    color: #ffffff;
}

/* Ease Display */
.pad {
    padding: 25px;
}