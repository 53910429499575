.portlet.ribbon {
    position: relative;
    padding: 2em 2em 1em;
    margin-bottom: 40px;
}
.portlet.ribbon:before {
    font-family: 'FontAwesome';
    content: " ";
    color: #fff;
    display: block;
    position: absolute;
    top: -10px;
    left: -18px;
    padding: 9px 18px 9px;
}
.portlet.ribbon:after {
    content: " ";
    display: block;
    position: absolute;
    border: 9px solid transparent;
    top: 26px;
    left: -18px;
}

.portlet.ribbon.success {
    /*-webkit-box-shadow: 2px 2px 10px 1px rgba(41, 66, 27, 1);*/
    /*-moz-box-shadow:    2px 2px 10px 1px rgba(41, 66, 27, 1);*/
    /*box-shadow:         2px 2px 10px 1px rgba(41, 66, 27, 1);*/
}
.portlet.ribbon.success:before {
    content: "\f00c    OK";
    background: #69aa46;
}
.portlet.ribbon.success:after {
    border-top-color: #2B5F75;
    border-right-color: #2B5F75;
}

.portlet.ribbon.warning {
    /*-webkit-box-shadow: 2px 2px 10px 1px rgba(132, 30, 42, 1);*/
    /*-moz-box-shadow:    2px 2px 10px 1px rgba(132, 30, 42, 1);*/
    /*box-shadow:         2px 2px 10px 1px rgba(132, 30, 42, 1);*/
}
.portlet.ribbon.warning:before {
    content: "\f044    Not Saved";
    background: #578ebe;
}
.portlet.ribbon.warning:after {
    border-top-color: #c5c5c5;
    border-right-color: #c5c5c5;
}

.portlet.ribbon.error {
    /*-webkit-box-shadow: 2px 2px 10px 1px rgba(132, 30, 42, 1);*/
    /*-moz-box-shadow:    2px 2px 10px 1px rgba(132, 30, 42, 1);*/
    /*box-shadow:         2px 2px 10px 1px rgba(132, 30, 42, 1);*/
}
.portlet.ribbon.error:before {
    content: "\f071    Incomplete";
    background: #DB4D6D;
}
.portlet.ribbon.error:after {
    border-top-color: #9F353A;
    border-right-color: #9F353A;
}
