/* Table header */
.renewals-wizard-table-header {
    background-color: #efefee;
    border-radius: 3px;
    padding: 13px;
    position: relative;
}

.renewals-wizard-table-header .renewals-wizard-table-icon {
    border-radius: 50%;
    color: #6d9bcc;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-weight: bold;
    padding: 0 0 5px 0;
    text-align: center;
    width: 30px;
    float: left;
}

.renewals-wizard-table-header .renewals-wizard-table-header-description {
    margin-left: 50px;
}

.renewals-wizard-table-header .renewals-wizard-table-header-description .btn-alert,
.renewals-wizard-table-header .renewals-wizard-table-header-description .btn-alert-yes-no {
    background-color: #6d9bcc;
    border-radius: 5px;
    color: white;
    padding: 8px;
    white-space: nowrap;
    text-shadow: none;
    text-decoration: none;
}

.renewals-wizard-table-header .renewals-wizard-table-header-description .btn-alert-yes-no {
    text-align: center;
    width: 30px;
}

.renewals-wizard-table-header .renewals-wizard-table-header-description .title {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
    padding-right: 35px;
    color: #333333;
}

.renewals-wizard-table-header-description-help {
    font-size:20px;
    padding:0 0 0 0;
}

.renewals-wizard-table-header .renewals-wizard-table-header-description .helper {
    padding: 0 20px 0 20px;
}

.renewals-wizard-table-header .renewals-wizard-table-header-description .helper.no-padding {
    padding: 0;
}

.renewals-wizard-table-header .renewals-wizard-table-header-description .helper p {
    line-height: 1.3em;
}

/* Table */
table.renewals-wizard-table {
    background-color: #efefee;
    border-radius: 3px;
    color: #474747;
    font-size: 13px !important;
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 30px !important;
}

@media (min-width: 768px) and (max-width: 1023px) {
    table.renewals-wizard-table {
        font-size: 13px;
    }
}

@media (min-width: 1024px) {
    table.renewals-wizard-table {
        font-size: 13px;
    }
}

table.renewals-wizard-table thead th,
table.renewals-wizard-table thead td,
table.renewals-wizard-table tbody th,
table.renewals-wizard-table tbody td {
    border: 1px solid #fafafa;
    padding: 10px;
}

table.renewals-wizard-table thead th {
    background-color: #bdbdbd;
    color: #fafafa;
    font-weight: normal;
    text-align: center;
}

table.renewals-wizard-table tbody tr:last-child td,
table.renewals-wizard-table tbody td.hold-positions-message {
    border-bottom: none;
}

table.renewals-wizard-table tbody td.license {
    text-align: left;
    padding: 10px;
    width: 250px;
}

table.renewals-wizard-table tbody tr td label {
    font-size: 13px;
}

/* Footer */
table.renewals-wizard-table tfoot tr {
    background: white;
    border: 1px solid #cccccc;
    text-transform: uppercase;
    font-weight: bold;
    color: black;
}

table.renewals-wizard-table tfoot tr td {
    border: none;
    font-size: 13px;
    text-align: left;
}

@media (min-width: 1024px) {
    table.renewals-wizard-table tfoot tr td {
        padding: 25px;
        font-size: 18px;
    }
}

@media (min-width: 1024px) {
    table.renewals-wizard-table tfoot tr td {
        padding: 25px;
        font-size: 18px;
    }
}

/***
Wizard done table
***/
.table.renewals-wizard-finished-table {
    border: 0 !important;
}

.table.renewals-wizard-finished-table > thead > tr:hover > th {
    background: none;
}

.table.renewals-wizard-finished-table > thead > tr.uppercase {
    text-transform: uppercase;
}

.table.renewals-wizard-finished-table > thead > tr > th {
    font-weight: 600;
    font-size: 13px;
    color: #474747;
    font-family: "Open Sans", sans-serif;
    border: 0;
    border-bottom: 1px solid #F2F5F8;
}

.table.renewals-wizard-finished-table > tbody > tr:last-child > td {
    border: 0;
}

.table.renewals-wizard-finished-table > tbody > tr > td {
    border: 0;
    border-bottom: 1px solid #F2F5F8;
    color: #474747;
    vertical-align: middle;
}

.table.renewals-wizard-finished-table > tbody > tr > td.highlight {
    background: #F9F9F9;
}

.table.renewals-wizard-finished-table > tbody > tr > td.fit {
    width: 1px;
    padding-right: 3px;
}

.table.renewals-wizard-finished-table > tbody > tr > td .user-pic {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
}

.table.renewals-wizard-finished-table.table-hover > tbody > tr > td:hover,
.table.renewals-wizard-finished-table.table-hover > tbody > tr > th:hover,
.table.renewals-wizard-finished-table.table-hover > tbody > tr:hover > td,
.table.renewals-wizard-finished-table.table-hover > tbody > tr:hover > th {
    background: #f9fafb;
}
