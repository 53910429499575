/* exam */
.portlet-exams {
    background-color: #fff;
    border: 2px solid #e6e9ec !important;
}

.portlet-exams > .portlet-title {
    padding: 12px 20px 15px 20px;
    margin-bottom: 0;
}

.portlet-exams .portlet-body,
.portlet-exams .portlet-body .form-actions  {
    padding: 12px 20px 15px 20px;
}

.portlet-exams .portlet-title .sitting h3 {
    font-weight: 300;
    font-size: 28px;
    margin: 15px 0 0 0;
}

.portlet-exams .portlet-title .sitting h4 {
    font-weight: bold !important;
    font-size: 18px;
    color: #d84a38 !important;
    margin-top: 0;
}

.portlet-exams .portlet-strip {
    opacity: 0.4;
    filter: alpha(opacity=40);
    height: 30px;
    font-size: 20px;
    padding: 0 20px 0 20px;
    vertical-align: bottom;
}

.portlet-exams .portlet-strip .left {
    float: left;
}
.portlet-exams .portlet-strip .right {
    float: right;
}

.portlet-exams .portlet-body .exam-schedule  h2 {
    font-weight: 300;
    font-size: 28px;
    margin: 15px 0 5px 0;
}

.portlet-exams .portlet-body .exam-schedule  address {
    margin-bottom: 25px;
}

.portlet-exams .portlet-body .exam-schedule  h3 {
    font-weight: 300;
    font-size: 28px;
    margin: 5px 0 20px 0;
}

.portlet-exams .portlet-body .exam-schedule  h4 {
    font-weight: bold !important;
    font-size: 18px;
    margin-bottom: 0;
}

.portlet-exams .portlet-footer {
    background-color: #F7F7F7;
    padding: 12px 20px 15px 20px;
}

.portlet-exams .portlet-footer .fa-ul > li {
    position: relative;
    line-height: 15px;
    margin-bottom: 10px;
}


/* Find my cipr */

/***
findmycipr page
***/
/* logo page */
.findmycipr {
    background-color: #444 !important;
}

.findmycipr .logo {
    margin: 0 auto;
    margin-top: 60px;
    padding: 15px;
    text-align: center;
}

.findmycipr .content {
    background-color: #fff;
    width: 1080px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
}

.findmycipr .content h3 {
    color: #000;
}

.findmycipr .content h4 {
    color: #555;
}

.findmycipr .content p {
    color: #222;
}

.findmycipr .content .findmycipr-form,
.findmycipr .content .forget-form {
    padding: 0px;
    margin: 0px;
}

.findmycipr .content .input-icon {
    border-left: 2px solid #35aa47 !important;
}

.findmycipr .content .input-icon .form-control {
    border-left: 0;
}

.findmycipr .content .forget-form {
    display: none;
}

.findmycipr .content .register-form {
    display: none;
}

.findmycipr .content .form-title {
    font-weight: 300;
    margin-bottom: 25px;
}

.findmycipr .content .form-actions {
    background-color: #fff;
    clear: both;
    border: 0px;
    border-bottom: 1px solid #eee;
    padding: 0px 30px 25px 30px;
    margin-left: -30px;
    margin-right: -30px;
}

.findmycipr .content .form-actions .checkbox {
    margin-left: 0;
    padding-left: 0;
}

.findmycipr .content .forget-form .form-actions {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 20px;
}

.findmycipr .content .register-form .form-actions {
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0px;
}

.findmycipr .content .form-actions .checkbox {
    margin-top: 8px;
    display: inline-block;
}

.findmycipr .content .form-actions .btn {
    margin-top: 1px;
}

.findmycipr .content .forget-password {
    margin-top: 25px;
}

.findmycipr .content .create-account {
    border-top: 1px dotted #eee;
    padding-top: 10px;
    margin-top: 15px;
}

.findmycipr .content .create-account a {
    display: inline-block;
    margin-top: 5px;
}

/* select2 dropdowns */
.findmycipr .content .select2-container {
    border-left: 2px solid #35aa47 !important;
}

.findmycipr .content .select2-container .select2-choice {
    border-left: none !important;
}

.findmycipr .content .select2-container i {
    display: inline-block;
    position: relative;
    color: #ccc;
    z-index: 1;
    top: 1px;
    margin: 4px 4px 0px -1px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    text-align: center;
}

.findmycipr .content .has-error .select2-container i {
    color: #b94a48;
}

.findmycipr .content .select2-container a span {
    font-size: 13px;
}

.findmycipr .content .select2-container a span img {
    margin-left: 4px;
}

/* footer copyright */
.findmycipr .copyright {
    text-align: center;
    margin: 0 auto;
    padding: 10px;
    color: #999;
    font-size: 13px;
}

@media (max-width: 480px) {
    /***
    findmycipr page
    ***/
    .findmycipr .logo {
        margin-top: 10px;
    }

    .findmycipr .content {
        width: 280px;
    }

    .findmycipr .content h3 {
        font-size: 22px;
    }

    .findmycipr .checkbox {
        font-size: 13px;
    }
}

/* Invoice css */

table.invoices tbody td.card .invoice {
    display: block;
    width: auto;
    text-align: left;
}

table.invoices tbody td.card .invoice .invoice-container {
    text-align: left;
    display: inline-block;
    margin-bottom: 5px;
    float: left;
}

table.invoices tbody td.card .invoice .invoice-container i {
    display: inline-block;
    padding: 5px 0 0 0;
    margin: 0;
    background-color: transparent;
    font-size: 14px;
}

table.invoices tbody td.card .invoice .invoice-container .invoice-number {
    display: inline-block;
    font-weight: bold;
    font-size: 13px;
}

table.invoices tbody td.card .invoice .invoice-container .invoice-owner {
    padding-left: 17px;
}

table.invoices tbody td.card .invoice .fee-container {
    text-align: left;
    display: inline-block;
    padding-left: 0px;
    line-height: 1.5;
    float: left;
    clear: both;
}

@media (min-width: 768px) and (max-width: 1023px) {
    table.invoices tbody td.card .invoice .fee-container {
        white-space: nowrap;
    }
}

@media (min-width: 1024px) {
    table.invoices tbody td.card .invoice .fee-container {
        white-space: nowrap;
    }
}

table.invoices tbody td.card .invoice .fee-container .fee-box,
table.invoices tbody td.card .invoice .fee-container .balance-box,
table.invoices tbody td.card .invoice .fee-container .to-arrow {
    display: inline-block;
}

table.invoices tbody td.card .invoice .fee-container .fee-box {
    margin-right: 2px;
}

table.invoices tbody td.card .invoice .fee-container .to-arrow {
    vertical-align: top;
    margin-right: 2px;
}

table.invoices tbody td.card .invoice .fee-container .fee-box .fee-label,
table.invoices tbody td.card .invoice .fee-container .balance-box .balance-label {
    font-weight: normal;
    /*text-transform: uppercase;*/
    color: #7a7a79;
    font-size: 13px;
}
table.invoices tbody tr.danger td.card .invoice .fee-container .fee-box .fee-label,
table.invoices tbody tr.danger td.card .invoice .fee-container .balance-box .balance-label {
    font-weight: normal;
    /*text-transform: uppercase;*/
    color: #a94442;
    font-size: 13px;
}


/* ng-hide for IE */
.ng-hide {
    display: none !important;
}


/*Renewals*/

table.renewals tbody td.card .license {
    display: block;
    width: auto;
    text-align: left;
    min-width: 200px;
}

table.renewals tbody td.card .license .license-icon {
    vertical-align: top;
    display: inline-block;
    padding: 5px 0 0 0;
    margin: 0;
    background-color: transparent;
    font-size: 14px;
}

table.renewals tbody td.card .license .license-icon.application {
    color: #8eb4e3;
}

table.renewals tbody td.card .license .license-icon.renewal {
    color: #8da230;
}

table.renewals tbody td.card .license .license-icon.active {
    color: #818181;
}

table.renewals tbody td.card .license .license-icon.fees {
    color: #86b62e;
}

table.renewals tbody td.card .license .description {
    text-align: left;
}

table.renewals tbody td.card .license .license-container {
    text-align: left;
    display: inline-block;
    padding-left: 5px;
}

table.renewals tbody td.card .license .license-container .license-number {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
}

table.renewals tbody td.card .license .license-container .license-descr {
    /*text-transform: uppercase;*/
    font-size: 13px;
    color: #474747;
    font-weight: normal;
}
table.renewals tbody tr.danger td.card .license .license-container .license-descr {
    /*text-transform: uppercase;*/
    font-size: 13px;
    color: #a94442;
    font-weight: normal;
}

table.renewals tbody td.card .license .license-container .corp-license {
    text-align: left;
    display: inline-block;
    margin-top: 10px;
    color: #474747;
}

table.renewals tbody td.card .license .license-container .corp-license i {
    font-size: 25px;
    padding: 10px 0 0 0;
    vertical-align: top;
}

table.renewals tbody td.card .license .license-container .corp-license .corp-license-container {
    text-align: left;
    display: inline-block;
    padding-left: 5px;
}

table.renewals tbody td.card .license .license-container .corp-license .corp-license-container .license-number {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    color: #474747;
}

table.renewals tbody td.card .license .license-container .corp-license .corp-license-container .license-descr {
    text-transform: capitalize;
    font-size: 10px;
    color: #474747;
    font-weight: normal;
}

i.clear-end-date {
    cursor: pointer;
    display: flex;
    margin-top: 10px;
}


/* Public Lookup pages */

.publiclookup {
    background-color: #eceef0 !important;
}

.publiclookup .content {
    background-color: #fff;
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 0px;
    padding: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: small;
}

.publiclookup .table td, .table th{
    font-size: small;
    padding: 10px 6px;
}

.publiclookup .table thead > tr > th {
    font-size: small;
}

.publiclookup .dataTables_wrapper {
    font-size: small;
}
.publiclookup .content .input-icon {
    border-left: 2px solid #35aa47 !important;
}

.publiclookup .content .input-icon .form-control {
    border-left: 0;
}
.publiclookup .label-info {
    background-color: #dddddd;
}
/* Overwrite pre begin */
.publiclookup pre {
    padding: 5px;
    margin: 5px 0 5px 0 !important;
    font-size: small;
 }

.publiclookup .form-group {
    margin-top: 10px;
    margin-bottom: 2px;
}

.publiclookup .content .form-actions {
    background-color: #fff;
    clear: both;
    border: 0px;
    border-bottom: 1px solid #eee;
    padding: 0px 30px 25px 30px;
    margin-left: -30px;
    margin-right: -30px;
}

.publiclookup .content .form-actions .btn {
    margin-top: 5px;
}

/* Calendar */
.publiclookup .text-info {
    color: black;
}

.publiclookup .text-muted {
    color: #77777778;
}

/* Footer copyright */
.publiclookup .copyright {
    text-align: center;
    margin: 0 auto;
    padding: 10px;
    color: #999;
    font-size: 13px;
    background-color:#0f1342;
}

/* Header Graphics & Containers */
.publiclookup .header-container {
    width:100%;
    margin:0;
    padding:0;
}

.publiclookup .header-box {
    background-color: #FFF;
    width:1200px;
    height:120px;
    margin:0px auto;
    padding:20px;
    position:relative;
}

.publiclookup .divider {
    border-bottom: 1px solid #eee;

    margin-bottom: 10px;
}

#logo-aic{float:left; }
#logo-aac{float:right; 	padding-top:10px;}

.publiclookup .header-container .header-menu{
    background-color:#2f3571;
}
.publiclookup .header-container .header-menu .header-menu-items-container{
    width:1200px;
    height:40px;
    margin:0px auto;
    position:relative;
    text-align: right;
    padding:10px;
    color:#fff;
}

.publiclookup .header-container .header-menu .header-menu-items-container a {
    color: #fff;
}

div.alphabet {
    clear:both;
    position:relative;
    margin:0.5em 0;
}


div.alphabet ul.pagination {
    margin:0;
}

div.alphabet ul.pagination > li {
    display:inline-block;
}

div.alphabet a span {
    opacity:1;
}

div.alphabet a.empty span {
    opacity:0.3;
}

div.alphabet a.empty.active span {
    opacity:1;
}

div.alphabet .alphabet-info-display {
    vertical-align:top;
    margin-right:0.5em;
}

div.alphabet div.alphabet-info {
    position:absolute;
    background-color:#111;
    border-radius:3px;
    color:#FFF;
    margin-top:0.2em;
    padding:0.2em 0.4em;
    text-align:center;
    opacity:0;
    transition:opacity .4s ease-in-out;
    z-index:9999;
}

.fa-2x {
    font-size: 1.8em !important;}
