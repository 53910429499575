/* top menu */
.page-header.navbar .hor-menu .navbar-nav > li.mega-menu-dropdown > .dropdown-menu {
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.15);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.15);
}

.page-header.navbar .page-logo {
    width: 50px !important;
}
.page-logo > a > i.fa {
    font-size: 25px;
    vertical-align: middle;
    margin: 20px 0 0 0;
}
.page-header.navbar .hor-menu .navbar-nav > li > a.switcher-menu {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    padding: 15px 15px;
}
/* top menu */

/* Overwrite Portlets begin */
.portlet > .portlet-body.light-blue, .portlet.light-blue {
    background-color: #bfd5fa !important;
}

.portlet.light > .portlet-title > .caption > i {
    color: #777;
    font-size: 20px;
    font-weight: 300;
}
/* Overwrite Portlets end */

/* Overwrite Fontawesome Icons begin */
[class^="fa-"],
[class^="glyphicon-"],
[class^="icon-"],
[class*=" fa-"],
[class*=" glyphicon-"],
[class*=" icon-"] {
    display: inline-block;
    margin-top: 1px;
    font-size: inherit;
    *margin-right: .3em;
    line-height: 14px;
    -webkit-font-smoothing: antialiased;
}
/* Overwrite Fontawesome Icons end */

/* Overwrite uniform sprite begin */
div.selector, div.selector span, div.checker span, div.radio span, div.uploader, div.uploader span.action, div.button, div.button span {
    background-image: url("/img/sprite.png");
    background-repeat: no-repeat;
    -webkit-font-smoothing: antialiased;
}
/* Overwrite uniform sprite begin */

/* Overwrite angular-datatables begin*/
.table-responsive {
    overflow-x: visible !important;
}
table.table thead .sorting_desc:before {
    content: '';
    padding-right: 0;
}

table.table thead .sorting_asc:before {
    content: '';
    padding-right: 0;
}
table.dataTable.no-footer {
    border-bottom: 1px solid #ddd !important;
}
table.dataTable thead .sorting {
    background-image: url("../img/sort_both.png");
}
table.dataTable thead .sorting_asc {
    background-image: url("../img/sort_asc.png");
}
table.dataTable thead .sorting_desc {
    background-image: url("../img/sort_desc.png");
}
table.dataTable thead .sorting_asc_disabled {
    background-image: url("../img/sort_asc_disabled.png");
}
table.dataTable thead .sorting_desc_disabled {
    background-image: url("../img/sort_desc_disabled.png");
}
/* Overwrite angular-datatables ends*/

/* Overwrite toastr begins */
.toast-top-right {
    top: 56px;
    right: 12px;
}
/* Overwrite toastr ends */

/* Override table buttons begins */
.table .btn {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}
/* Override table buttons ends */

/* Angular begins */
[ng-click],
[data-ng-click],
[x-ng-click] {
    cursor: pointer;
}
[ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
}
/* Angular ends */

.date-picker {
    min-width: 200px;
    max-width: 200px;
}

/* Sweet alert begins */
.sweet-alert .sa-icon {
    width: 80px;
    height: 80px;
    border: 4px solid gray;
    -webkit-border-radius: 40px;
    border-radius: 50% !important;
    margin: 20px auto;
    padding: 0;
    position: relative;
    box-sizing: content-box;
}
/* Sweet alert ends */

/* Text */
.dark-text {
    color: #333 !important;
}

.black-text {
    color: #666;
}

.white-text {
    color: #fff !important;
}

.bright-red-text {
    color: #e02222 !important;
}

.red-text {
    color: #ed4e2a;
}

.pale-red-text {
    color: #dd5a43 !important;
}

.light-red-text {
    color: #f77 !important;
}

.bright-blue-text {
    color: #47d4e0;
}

.blue-text {
    color: #4d90fe !important;
}

.blue2-text {
    color: #428bcf;
}

.light-blue-text {
    color: #93cbf9 !important;
}

.brown-text {
    color: #a52a2a !important;
}

.bright-green {
    color: #22da40;
}

.green-text {
    color: #69aa46 !important;
}

.light-green-text {
    color: #b0d877 !important;
}

.orange-text {
    color: #ff892a !important;
}

.pale-orange-text {
    color: #ffa352;
}

.light-orange-text {
    color: #fcac6f !important;
}

.orange2-text {
    color: #feb902 !important;
}

.yellow-text {
    color: #f0ad4e;
}

.bright-pink-text {
    color: #eb2bd4;
}

.pink-text {
    color: #c6699f !important;
}

.pink2-text {
    color: #d6487e !important;
}

.purple-text {
    color: #852b99 !important;
}

.light-purple-text {
    color: #954bb9;
}

.grey-text {
    color: #777 !important;
}

.light-grey-text {
    color: #bbb !important;
}

.light-text {
    font-style: italic !important;
    font-weight: 200 !important;
}

.right-aligned-text {
    text-align: right;
}

.paid {
    background-image: url('/img/paid.svg');
    min-height: 100px;
    opacity: 0.5;
    background-repeat: no-repeat;
}
.paymentreceived {
    background-image: url('/img/paymentreceived.jpg');
    min-height: 150px;
    opacity: 0.5;
    background-repeat: no-repeat;
}

.dashed-line  {
    border-top: 1px dashed #000;
    height: 18px;
    no-repeat: 10px -18px;
}

.modal .modal-header {
    border-bottom: 1px solid #EFEFEF;
    margin-top: 10px !important;
}

.validation-error {
    color: #9F353A
}

/* margins */
.padding-none {
    padding: 0 0 0 0 !important;
}

.margin-none {
    margin: 0 0 0 0 !important;
}

.margin-no-bottom {
    margin-bottom: 0 !important;
}

.margin-no-top {
    margin-top: 0 !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.margin-left-20 {
    margin-left: 20px !important;
}


/* Overwrite pre begin */
pre {
    display: block;
    padding: 9.5px;
    margin: 10px 0 10px 0 !important;
    font-size: 14px;
    line-height: 1.42857143;
    color: #000;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
}
/* Overwrite pre end */

/* ui select */
.ui-select-container {
    padding: 0 !important;
    border: 0 !important;
}
.ui-select-bootstrap .ui-select-choices-row.active > a {
    text-decoration: none;
    outline: 0;
    color: #333333;
    background-color: rgba(51, 122, 183, 0.2);
}
.ui-select-bootstrap>.ui-select-choices {
    margin-top: 1px !important;
}
.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    width: 100%!important;
    height: 34px!important;
    padding: 6px 12px!important;
    background-color: #fff!important;
    border: 1px solid #ccc!important;
}
/* ui select */
